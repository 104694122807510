export enum BasicConstantType {
  role_type = 'role_type',
  social_account_type = 'social_account_type',
  shift_hour_type = 'shift_hour_type',
  shift_time_type = 'shift_time_type',
  working_hours_type = 'working_hours_type',
  care_scope = 'care_scope',
  care_type = 'care_type',
  education_type = 'education_type',
  reference_type = 'reference_type',
  match_status = 'match_status',
  match_stage = 'match_stage',
  specification_job_care_type = 'specification_job_care_type',
  special_requirement_job_type = 'special_requirement_job_type',
  gender_type = 'gender_type',
  channel_type = 'channel_type',
  trigger_type = 'trigger_type',
  continuing_education_type = 'continuing_education_type',
  vaccination_status_type = 'vaccination_status_type',
  job_driving_license_type = 'job_driving_license_type',
  driver_license_type = 'driver_license_type',
  budget = 'budget',
  position = 'position',
  area_of_experience = 'area_of_experience',
  work_experience = 'work_experience',
  language_skill = 'language_skill',
  salutation = 'salutation',
  ownership = 'ownership',
  ats_software = 'ats_software',
  company_size = 'company_size',
  applicant_source = 'applicant_source',
  company_match_status = 'company_match_status',
  applier_status = 'applier_status',
  applier_flag = 'applier_flag',
  match_flag = 'match_flag',
  actor = 'actor',
  job_status = 'job_status',
  company_actor = 'company_actor',
  user_file_type = 'user_file_type'
}

export enum ExtendedConstantType {
  extended_care_type = 'extended_care_type',
  extended_position = 'extended_position',
  extended_education = 'extended_education',
  extended_continuing_education = 'extended_continuing_education'
}

export const ConstantType = { ...BasicConstantType, ...ExtendedConstantType };

export interface BaseConstant {
  readonly id?: number | string;
  readonly label: string;
  readonly children_ids?: (number | string)[];
}

export type BasicConstant = Pick<Required<BaseConstant>, 'id' | 'label'>;

export type ExtendedConstant = {
  [key: string]: BaseConstant;
};

export type Constants = {
  [key in BasicConstantType]: readonly BasicConstant[];
} & {
  [key in ExtendedConstantType]: ExtendedConstant;
};
